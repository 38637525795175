/**
* Helper Component for getting env source
* @author {Cognizant Technology Solutions}
* @flow
*/
import env from '../env.json';

export const prodOkta = env.APISource === 'PROD' || env.APISource === 'DR';

export const showForPROD = env.APISource === 'PROD' || env.APISource === 'NONPROD2' || env.APISource === 'NONPROD6' || env.APISource === 'NONPROD8' || env.APISource === 'DR';

export const showForNONPROD = ['DEV', 'NONPROD1', 'NONPROD3', 'NONPROD4', 'NONPROD5', 'NONPROD7', 'PLAYGROUND'].includes(env.APISource);

export const showForTypePayload = ['DEV', 'NONPROD1', 'NONPROD2', 'NONPROD3', 'NONPROD4', 'NONPROD5', 'NONPROD6', 'NONPROD7', 'NONPROD8', 'PROD', 'PLAYGROUND', 'DR'].includes(env.APISource);

export const pricingNonProd = ['DEV', 'NONPROD1', 'NONPROD2', 'NONPROD3', 'NONPROD4', 'NONPROD5', 'NONPROD6', 'NONPROD7', 'NONPROD8', 'PROD', 'PLAYGROUND', 'DR'].includes(env.APISource);

export const showForDev = env.APISource === 'DEV' || env.APISource === 'PLAYGROUND';

export const showForAF = ['DEV', 'NONPROD1', 'NONPROD3', 'NONPROD4', 'NONPROD5', 'NONPROD7', 'PLAYGROUND', 'DR'].includes(env.APISource);

export const showForMANA = ['NONPROD3', 'NONPROD7'].includes(env.APISource);

export const showForPSQA = ['NONPROD2', 'NONPROD6', 'NONPROD8'].includes(env.APISource);

export const showForFWTooling = ['DEV', 'NONPROD1', 'NONPROD3', 'NONPROD4', 'NONPROD5', 'NONPROD7', 'PLAYGROUND', 'DR'].includes(env.APISource);

export const showForFeatureFlag = ['DEV', 'NONPROD1', 'NONPROD3', 'NONPROD4', 'NONPROD5', 'NONPROD7', 'PLAYGROUND'].includes(env.APISource);
export const showForFeatureFlagDependency = true;

export const removeAFDivision = true;
