/**
 * This file is used for Menu details.
 * @author {Cognizant Technology Solution}
 */
import imagesPath from './imagesPath';
import {
  showForAF,
  showForFeatureFlag,
  showForPROD
} from '../helpers/envServer';
import { ACTIVE_FEATURES } from './featureFlag';
import appConfig from '../appConfig';

export default [
  {
    name: 'Dashboard',
    path: {
      src: imagesPath.iconDashboard.src,
      alt: imagesPath.iconDashboard.alt
    },
    link: '/',
    subMenu: [{
      link: '/',
      name: 'Primary Dashboard'
    },
    {
      link: '/operationalmetrics/',
      name: 'Operational Metrics',
      appInsight: true
    }],
    relatedLinks: [
      '/operationalmetrics',
      '/'
    ],
    active: true
  }, {
    name: 'P.O. Search',
    path: {
      src: imagesPath.iconSearch.src,
      alt: imagesPath.iconSearch.alt
    },
    link: '/posearch/',
    relatedLinks: [
      '/posearch',
      '/poSearch'
    ]
  }, {
    name: 'P.O. Reporting',
    path: {
      src: imagesPath.iconReporting.src,
      alt: imagesPath.iconReporting.alt
    },
    relatedLinks: [
      '/ppmreport',
      '/ppmreport/',
      '/poreport/',
      '/poreport',
      '/fxreport',
      '/fxreport/',
      '/pochangereport/',
      '/pochangereport',
      '/lineitemreport',
      '/lineitemreport/',
      '/conversationreport',
      '/conversationreport/',
      '/airfreightreport',
      '/airfreightreport/',
      '/gacchangehistoryreport',
      '/gacchangehistoryreport/'
    ],
    subMenu: [{
      link: '/ppmreport/',
      name: 'Production Progress & Metrics'
    },
    {
      link: '/poreport/',
      name: 'Collaboration History Report'
    },
    {
      link: '/pochangereport/',
      name: 'PO Change Report'
    },
    {
      link: '/lineitemreport/',
      name: 'Line Item Summary Report'
    },
    {
      link: '/conversationreport/',
      name: 'Conversation Thread Report'
    },
    {
      link: (showForAF) ? '/airfreightreport/' : '',
      name: (showForAF) ? 'Air Freight Report' : '',
      featureFlag: ACTIVE_FEATURES.AIR_FREIGHT_REPORT
    },
    {
      link: '/gacchangehistoryreport/',
      name: 'GAC Change History Report'
    }
    ]
  }, {
    name: 'Conversations',
    path: {
      src: imagesPath.iconConversation.src,
      alt: imagesPath.iconConversation.alt
    },
    link: '/conversationthreads/',
    relatedLinks: ['/conversationthreads', '/conversationThreads']
  }, {
    name: 'Upload/Download',
    path: {
      src: imagesPath.iconUpload.src,
      alt: imagesPath.iconUpload.alt
    },
    link: '/upload/0',
    relatedLinks: [
      '/upload/1',
      '/upload/2',
      '/upload/3',
      '/download/0',
      '/download/1'
    ]
  }, {
    name: 'SIRE Delivery Profile',
    path: {
      src: imagesPath.iconSire.src,
      alt: imagesPath.iconSire.alt
    },
    link: '/sireprofile/',
    relatedLinks: ['/sireprofile']
  }, {
    name: 'Saved Searches',
    path: {
      src: imagesPath.iconBookmark.src,
      alt: imagesPath.iconBookmark.alt
    },
    link: '/savedsearches/0',
    relatedLinks: [
      '/savedsearches/0',
      '/savedsearches/reports/0',
      '/savedsearches/reports/1',
      '/savedsearches/reports/2',
      '/savedsearches/reports/3',
      '/savedsearches/reports/4',
      '/savedsearches/reports/5',
      '/savedsearches/reports/6'
    ]
  },
  {
    name: (showForAF) ? 'AF Adjustments' : '',
    path: {
      src: imagesPath.wrapTextIcon.src,
      alt: imagesPath.wrapTextIcon.alt
    },
    link: (showForAF) ? '/airfreightoverride/0' : '',
    relatedLinks: ['/airfreightoverride/0', '/airfreightoverride/1'],
    showForPMOUsers: true
  },
  {
    name: 'Settings',
    path: {
      src: imagesPath.iconSettings.src,
      alt: imagesPath.iconSettings.alt
    },
    link: '/Settings',
    relatedLinks: [
      '/Settings',
      '/settings/pmodec/',
      '/settings/pmodec',
      '/settings/geoglobal/',
      '/settings/geoglobal',
      '/settings/geoEmailGroup/',
      '/settings/geoEmailGroup',
      '/settings/conversationcategorycodes/',
      '/settings/conversationcategorycodes',
      '/settings/gacreasoncodes/',
      '/settings/gacreasoncodes',
      '/settings/gacreasonusecases/',
      '/settings/gacreasonusecases',
      '/settings/featureFlag/',
      '/settings/featureFlag'
    ],
    subMenu: (appConfig.showPMOSettings && !showForPROD) ? (
      [{
        link: '/Settings/',
        name: 'My Settings'
      },
      {
        link: '/settings/pmodec/',
        name: 'PMO/DEC Factory Contact'
      },
      {
        link: '/settings/geoglobal/',
        name: 'Stakeholder Config'
      },
      {
        link: '/settings/geoEmailGroup/',
        name: 'Email Group Maintenance'
      },
      {
        link: '/settings/conversationcategorycodes/',
        name: 'Conversation Category Codes'
      },
      {
        link: '/settings/gacreasoncodes/',
        name: 'GAC Reason Code'
      },
      {
        link: '/settings/gacreasonusecases/',
        name: 'GAC Reason Use Cases'
      },
      {
        link: showForFeatureFlag ? '/settings/featureFlag/' : '',
        name: showForFeatureFlag ? 'Feature Flag' : ''
      }
      ]) : (
      [{
        link: '/Settings/',
        name: 'My Settings'
      },
      {
        link: '/settings/geoglobal/',
        name: 'Stakeholder Config'
      },
      {
        link: '/settings/geoEmailGroup/',
        name: 'Email Group Maintenance'
      }
      ])
  },
  {
    name: 'External Link',
    path: {
      src: imagesPath.ExternalLink.src,
      alt: imagesPath.ExternalLink.alt
    },
    link: '/usefulLinks/',
    relatedLinks: ['/usefulLinks']
  }
];
